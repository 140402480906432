.admin-layout {

.SubscriptionPage {
  margin: 0 auto;

  h2 {
    font-size: 1.3rem;

    @mixin below $medium {
      margin-left: 0;
    }
  }

  .PremiumPromo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: $bg;
    margin-bottom: 3rem;

    h4 {
      font-weight: 350;
      font-size: 1.75rem;
    }

    p {
      font-size: 1.25rem;
      strong {
        text-transform: uppercase;
      }
    }

    &-cost {
      font-weight: 500;
      font-size: 3.5rem;
      line-height: 1;
    }

    &-downgrade {
      font-size: .8rem;
      color: #b3b9c1;

      .Button.LinkButton {
        display: inline-block;
        padding: 0;
        border-radius: 0;
        color: #b3b9c1;
        border-bottom: 1px dashed #b3b9c1;

        &:hover {
          color: $text-light;
          border-bottom-color: $text-light;
        }
      }
    }

    img {
      height: 200px;
      margin-left: -10px;
      margin-right: 1rem;
    }

    @mixin above $medium {
      & > div {

        &:last-child {
          margin: 0 1.5rem;
        }

        &:not(:last-child) {
          margin-right: 1rem;
        }
      }
    }

    @mixin below $medium {
      flex-direction: column;

      & > div {
        margin: 1.5rem 0;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

  }
}

.CateringLayout {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .CateringLayout-index {
    display: block;
  }

  .CateringLayout-detail {
    display: none;
  }

  @mixin above $medium {
    flex-direction: row;
    flex-wrap: nowrap;
    height: calc(100vh - 111px);
    min-height: 500px;
    overflow: hidden;

    .CateringLayout-detail {
      display: block;
    }
  }
}

.CateringLayout {
  @mixin below $medium {
    margin-right: -1rem;
    margin-left: -1rem;
    margin-bottom: -1rem !important;

    &--requestSelected {
      .CateringLayout-index {
        display: none;
      }

      .CateringLayout-detail {
        display: block;
      }
    }
  }
}

.CateringLayout-filters {
  display: flex !important;
  margin-top: .25rem;

  &-inner {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin: -.25rem;
  }

  label span {
    cursor: pointer;
  }

  .Select {
    width: 200px;
    margin: .25rem;

    .Select-clear {
      padding-top: 6px;
    }

    .Select-input {
      padding-top: 4px;
    }

    .Select-input input {
      padding: 0;
    }

    .Select-menu-outer {
      z-index: 5;
    }
  }
}

.CateringLayout-filter-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;

  &--requestSelected {
    @mixin below $medium {
      display: none;
    }
  }
}

.CateringLayout-index {
  width: 100%;
  position: relative;
  z-index: 2;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background-color: white;

  &.CateringLayout-customer {
    @mixin above $medium {
      border-right: $border;
      flex: 0 0 280px;
      max-width: 280px;
    }
  }

  &.CateringLayout-vendor {
    @mixin above $medium {
      border-right: $border;
      flex: 0 0 380px;
      max-width: 380px;
    }
  }
}

.CateringLayout-detail {
  position: relative;
  z-index: 5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  width: 100%;
  transition: transform 200ms ease-out-quad;
  padding: 0;

  @mixin above $medium {
    flex: 1 1 100%;
    height: auto;
    z-index: 1;
    position: relative;
    top: 0;
    transform: none;
  }
}

.CateringLayout-detail--requestSelected {
  @mixin above $medium {
    transform: none;
  }
}

.CateringRequestWrapper {
  @mixin above $medium {
    /* min-height: 592px; */
  }
  padding-bottom: 5rem;
}

.CateringRequest {
  position: relative;
  background: white;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  @mixin above $medium {

    .u-grid-col {
      margin-bottom: 1rem;
    }

    .u-grid-row:last-child {
      .u-grid-col {
        margin-bottom: 0;
      }
    }
  }
}

.CateringRequest-noSelected,
.BidDetail-noSelected {
  text-align: center;
  color: $text-light;
  max-width: 250px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.CateringRequest-header {
  border-radius: $radius $radius 0 0;
  background: $black;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;

  h5 {
    flex: 1 1 50%;
  }
}

.CateringRequest-headerActions {
  flex: 0 1 50%;
  text-align: right;
  .Button {
    margin-bottom: 0;
  }
}

.CateringRequest-overview {
  background: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  border-bottom: 1px solid rgba(0,0,0,0.15);
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  position: relative;
  z-index: 3;
  flex-shrink: 0;

  img {
    height: 75px;
    margin-right: .75rem;
  }

  &-main {
    display: flex;
  }

  @mixin above $medium {
    & > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 1rem;
      border-right: $border;

      &:first-child {
        flex-grow: 1;
        justify-content: flex-start;
      }

      &:last-child {
        border: none;
      }
    }

    .CateringRequest-overview-main {
      flex-direction: row;
      justify-content: flex-start;
      width: 100%;
    }

    .CateringRequest-overview-details {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      padding: 0;

      & > div {
        text-align: center;
        border-right: $border;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: .75rem;

        &:last-child {
          border: none;
        }
      }
    }
  }

  @mixin below $medium {
    flex-direction: column;

    & > div {
      padding: .5rem;

      &:first-child {
        display: flex;
        border-bottom: $border;
        padding: 0;
      }
    }

    .CateringRequest-overview-main {
      padding: .5rem;

      & > div {
        min-width: 1px;
      }

      .u-text-light {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .CateringRequest-overview-details {
      display: flex;
      justify-content: space-around;
      text-align: center;
    }
  }
}

.CateringRequest-overview-title {
  @mixin secondary-text;
}

.CateringRequest-overview-value {
  font-weight: 500;
  font-size: 18px;
}

.CateringRequest-bidsHeader {
  img {
    height: 200px;
  }
}

.CateringRequest-bidButtons,
.CateringRequest-editButtons {
  display: flex;
  flex-direction: column;
  justify-content: center;

  @mixin above $medium {
    display: block;
    border-right: none;
    padding-right: 0;
  }
}

.CateringRequest-bidDetailWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;

  button {
    margin: 0;
  }

  @mixin above $medium {
    display: none;
  }
}

.CateringRequest-detail {
  background: white;
  padding: 1.5rem;
}

.CateringRequest-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1600;
  background: rgba(185, 185, 185, 0.7);
}

.CateringRequest-overlayContent {
  position: absolute;
  background: white;
  top: 50%;
  left: 50%;
  width: 300px;
  height: auto;
  transform: translateX(-50%) translateY(-50%);
  padding: 2rem;
  border-radius: $radius;
  text-align: center;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);

  @mixin above $small {
    width: 500px;
  }
}

.CateringRequest-overlayIcon {
  font-size: 4rem;
  line-height: 1;
}

.CateringRequestPreview-container {
  border-bottom: $border;
}

.CateringRequestPreview {
  display: block;
  text-align: left;
  background: white;
  padding: 0.75rem;
  font-size: 0.875rem;
  color: $black;
  transition: background $hover-speed, border $hover-speed;
  border-left: 4px solid white;
  cursor: pointer;

  &-body {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    line-height: 1.5;

    img {
      width: 55px;
    }
  }

  &-left {
    min-width: 1px;
  }

  &-right {
    min-height: 100%;
    align-self: stretch;
  }

  &:hover {
    background: $primary-bg;
    border-left: 4px solid $primary-bg;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

.CateringRequestPreview--selected {
  border-left: 4px solid $primary !important;
  background: $primary-bg;
}

.CateringRequestPreview--bidRetracted {
  opacity: 0.4;
}

.CateringRequestPreview-actions {
  text-align: right;
  margin-top: auto;

  .Button.LinkButton {
    font-size: 1.25rem;
    line-height: 1;
    color: $text-light;
  }

  .Button svg {
    height: 18px;
    width: 18px;
  }

  .Button--selected svg {
    fill: $admin-red;
  }
}

.CateringRequestPreview-budget {
  font-weight: 600;
  color: $black;
  font-size: 1rem;

  span {
    font-size: 0.875rem;
    margin-left: 0.1rem;
  }
}

.CateringRequestPreview-bid {
  color: $admin-green;
}

.CateringRequestPreview-bid--retracted {
  color: $admin-red;
  text-decoration: line-through;
}

.Retracted,
.CateringRequestBid--retracted .CateringRequestBid-truckName {
  text-decoration: line-through;

  &:after {
    display: inline-block;
    color: $admin-red;
    margin-left: 0.5rem;
    font-size: 1rem;
    content: 'Bid retracted';
    text-decoration: none;
  }
}

.CateringRequestBid {
  background: white;
  overflow: hidden;
  transition: background $hover-speed, border $hover-speed;
  border-top: 1px solid #eee;

  &:nth-child(even) {
    background-color: #f7f7f7;
  }
}

.CateringRequestBid--retracted {
  opacity: 0.6;
}

.CateringRequestBid-truckInfo {
  display: block;
  position: relative;
  margin: 0;
  line-height: 1;
  flex: 0 1 100%;
  padding: 0.75rem 0.75rem;
  transition: color $hover-speed;
}

.CateringRequestBid-truckName {
  font-weight: 600;
  display: block;
  font-size: 1rem;
  margin-bottom: 0.3rem;
  color: $black;
  transition: color $hover-speed;

}

.CateringRequestBid-truckCategories {
  font-size: 0.75rem;
  line-height: 1;
  margin-bottom: 0.4rem;
  display: block;
}

.CateringRequestBid-rating {
  margin-top: 0.25rem;
  display: block;
}

.CateringRequestBid-amount {
  font-weight: 600;
  font-size: 1.25rem;
  flex: 0 0 75px;
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
  text-align: right;
  transition: color $hover-speed;
  align-self: center;
}

.CateringRequestBid-comments {
  font-weight: 500;
  font-size: 1rem;
  color: $grey;

  .icon {
    line-height: 0;
    position: relative;
    top: 0.1rem;
    margin-right: 0.3rem;
  }
}

.CateringRequestPreview-truckImg {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 60px;
  width: 60px;
  flex: 0 0 60px;
  border-radius: 50%;
}

.CateringRequestBid-expandCollapse {
  line-height: 1;
  vertical-align: middle;
  top: 0.2rem;
  position: relative;
  text-align: right;
  padding-right: 0.75rem;
  color: $grey;
  transition: color $hover-speed;
  align-self: center;
}

.CateringRequestBid-body {
  padding: 1rem;
}

.CateringRequestBid-notes {
  font-size: 0.875rem;
  margin-bottom: 0.75rem;
  h5 {
    margin-bottom: 0.25rem;
  }
}

.CateringRequestBid-buttons {
  border-top: 1px solid #ddd;
  padding-top: 0.75rem;

  .Button {
    margin-bottom: 0;
  }
 }

 .CateringRequestBid-book {
   background: $black;
   color: white;
   padding: 1rem;

   h5 {
    margin-bottom: 0.25rem;
   }

   p {
     font-size: 0.875rem;
     margin-bottom: 0.5rem;
   }
 }

.CateringRequestBid-heart {
  vertical-align: middle;
  display: inline-block;
  fill: $admin-red;
  z-index: 2;
  font-size: 1rem;
  height: 17px;
  width: 17px;
  margin-right: -2px;
}

.CateringRequestBid-star {
  vertical-align: middle;
  display: inline-block;
  color: $yellow;
  z-index: 2;
  font-size: 1rem;
}

.BidCost {
  background: $bg;
  border-radius: $radius;
  padding: 0.75rem 1rem;
}

.Benefit {
  padding-bottom: 2rem;

  img {
    max-width: 125px;
    margin-bottom: 1rem;
  }

  h3 {
    font-size: 1.15rem;
  }

  p {
    font-size: 1rem;
  }

  @mixin above $small {
    padding-bottom: 0;
  }
}

.Benefit--big {
  img {
    max-width: 100%;
    margin-bottom: .5rem;
  }
}

.CateringCustomerImg {
  display: inline-block;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-size: cover;
  background-position: center center;
}

.CateringRequestsList {
  border-radius: $radius;
  overflow: hidden;
  border: $border;

  .CateringRequestPreview-container {
    &:last-child {
      border-bottom: none;
    }
  }
}

.CustomerCateringRequest {
  padding: 2.5rem;
  padding-top: 1rem;
  min-height: calc(100vh - 200px);

  @mixin below $medium {
    padding: 1rem;
    min-height: unset;
  }
}

.CustomerCateringRequest-header {
  margin-bottom: 1rem;

  &-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @mixin below $medium {
    &-actions {
      flex-direction: column;
      margin-bottom: 1rem;
    }

    .BackLink {
      margin-bottom: 1rem;
    }
  }
}

.CustomerCateringRequest-info {
  .u-grid-col {
    margin-bottom: 1rem;

    & > div + div {
      margin-top: 1rem;
    }
  }
}

.CustomerCateringRequest-actions {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-top: -.25rem;
  margin-bottom: -.25rem;

  .Select-menu-outer {
    z-index: 100;
  }

  .Select-value {
    margin-top: 0 !important;
    margin-left: 0 !important;
    background: none !important;

    .Select-value-label {
      padding-left: 0;
      padding-top: 6px;
    }
  }

  @mixin below $medium {
    flex-direction: column-reverse;

    &.bid-selected {
      display: none;
    }
  }
}

.CustomerCateringRequest-filters {
  display: flex;
  align-items: flex-end;
}

div.CustomerCateringRequest-edit {
  margin-bottom: .25rem;

  @mixin above $medium {
    margin-left: auto;
    margin-bottom: 0;
    margin-top: .25rem;
  }
}

.CustomerCateringRequest-dismiss,
.CateringRequest-dismiss  {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
  @mixin above $medium {
    display: none;
  }
}

.CustomerCateringRequest-bidComments {
  justify-content: flex-start;
  background: #dcdcdc;
}

.CustomerCateringRequest-closed {
  font-weight: 600;
  display: inline-block;
  background: $admin-red;
  padding: 0.2rem 0.25rem;
  line-height: 1;
  border-radius: 2px;
  color: white;
  font-size: 0.675em;
  text-transform: uppercase;
  margin-right: 0.5rem;
  vertical-align: top;
  position: relative;
  top: 2px;
}

/* BidDetail */

.BidDetail-header {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  border-bottom: 1px solid rgba(0,0,0,0.15);
  align-content: center;
  justify-content: space-between;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  z-index: 3;
  flex-shrink: 0;

  @mixin above $medium {
    flex-direction: row;
    flex-wrap: nowrap;
  }
}

.BidDetail-innerHeader {
  padding: 0.75rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: center;
  flex: 1 1 100%;

  h3 {
    padding-top: 4px;
  }

  .BidDetail-amount {
    color: $admin-green;
  }

  @mixin above $medium {
    flex-direction: row;
    flex-wrap: nowrap;

    h3 {
      white-space: nowrap;
    }
  }

  @mixin below $medium {
    padding-top: .5rem;
    padding-bottom: .5rem;

    & > div {
      margin-top: .5rem;
      margin-bottom: .5rem;
    }
  }
}

.BidDetail-truckPhoto {
  flex: 0 0 100%;
  height: 150px;
  display: block;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: $radius 0 0 0;
  overflow: hidden;
  border: 1px solid white;

  @mixin above $medium {
    flex: 1 1 50%;
    max-height: 100%;
    max-width: 200px;
    height: 200px;
  }
}

.BidDetail-body {
  padding: 1rem;
  flex: 1 1 50%;
}

.BidDetail-bidInfo {
  padding: 1rem;
}

.BidDetail-truckDescription {
  margin-bottom: 1rem;
  @mixin above $small {
    font-size: 0.875rem;
  }
}

.BidDetail-truckCategories {
}

.BidDetail-rating {
  margin-bottom: 0.5rem;
}

.BidDetail-notes {
  @mixin above $small {
    font-size: 0.875rem;
  }
}

.BidDetail-profileLink {
  margin-right: 0.75rem;
}

.CateringRequest-back,
.BidDetail-back {
  display: none;

  @mixin below $medium {
    display: flex;
    flex: 0 0 50px;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    line-height: 0;
    border-right: $border;
  }

  button, a {
    color: #433e36;
    vertical-align: middle;
    height: 100%;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .icon {
    font-size: 2rem;
    line-height: 0;
  }
}

.BidDetail-actions {
  align-self: center;
  display: flex;

  button.icon {
    font-size: 1.75rem;
    line-height: 1;
    position: relative;
    margin-right: 0.75rem;
    top: 0.1rem;
    color: $border-color;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      color: $primary-dark;
    }
  }

  .BidDetail-trashButton,
  .BidDetail-heartButton {
    border: none;
    cursor: pointer;
    background: none;
    padding: 0;

    svg {
      height: 29px;
      width: 29px;
      fill: $mediumGrey;
    }

    &:hover svg {
      fill: $primary-dark;
    }

    &:focus {
      outline: none;
    }

    &--selected svg {
      fill: $admin-red;
    }
  }

  .BidDetail-heartButton {

    &--selected svg {

    }
  }
}

.CateringRequestBidWrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
  height: calc(100vh - 40px - 66px - 76px - 80px);
  padding: 1rem;

  @mixin above $medium {
    height: calc(100vh - 69px - 76px - 88px);
  }
}

.CateringRequest--vendor .CateringRequestBidWrapper {
  height: calc(100vh - 77px - 41px - 67px);

  @mixin above $medium {
    height: calc(100vh - 212px);
  }
}

.BidEdit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background: rgba(0,0,0,0.25);
}

.BidEdit-inner {
  width: 300px;
  height: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 10;
  padding: 1rem;
  background: white;
  border-radius: $radius;
  transform: translateX(-50%) translateY(-50%);
}

.CateringRequestPreview-title {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.CateringRequestPreview-titleContainer {
  min-width: 1px;
}

.CateringRequestPreview-title-budget {
  font-weight: 500;
  color: $admin-green;
}

/* .CateringRequestPreview-description {
  line-height: 1.5;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &-container {
    min-width: 0;
  }
} */

.CateringWelcome {
  .BackLink {
    position: absolute;
    top: 0;
    left: 2rem;
  }
}

.TruckProfilePane {

  .slide-pane__header {
    flex: 0;
  }

  .slide-pane__close {
    z-index: 10;
    position: absolute;
    right: 0;
    top: 0;
  }

  .slide-pane__content {
    padding-right: 0;
    padding-left: 0;
  }

  &-img {
    height: 300px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }

  &-content {
    padding: 2rem;
    padding-bottom: 0;
  }
}

/*
 * Catering request creation/editing
 */
.ManageCateringRequest {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 64px);

  .ProgressCounter {
    margin-top: auto;
    padding-bottom: 3rem;
  }

  .CateringOccasionSelect {

    .Select-option {
      padding-bottom: 5px;
    }

    .Select-option,
    .Select-value-label {

      .u-flex {
        align-items: center;

        & > span {
          line-height: 1;
          padding-top: 3px;
        }
      }

      img {
        height: 28px;
      }
    }
  }

  .EventTypeField {
    display: flex;

    .RadioGroup {
      display: flex;
      width: 100%;

      label {
        width: 100%;
        margin: 0;

        span {
          margin: 0 !important;
        }
      }

      input:checked + label,
      input.selected + label {
        background: $bg;
        color: $primary-dark;
      }

      input[type=radio] {
        position: absolute !important;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        width: 1px;
        border: 0;
        overflow: hidden;
      }

      & > div {
        flex-grow: 1;

        &:first-child {
          margin-right: 1rem;
        }
      }
    }

    .Button,
    .RadioGroup label {
      font-weight: 500;
      cursor: pointer;
      flex-grow: 1;
      border-radius: 4px;
      background: none;
      border: 1px solid $primary;
      color: $primary;
      padding: 2rem 4rem;
      padding-bottom: 1rem;

      &:hover {
        border: 1px solid $primary-dark;
        color: $primary-dark;
        background: $bg;
      }
    }

    .Button:first-child {
      margin-right: 1rem;
    }

    img {
      height: 75px;
      margin-bottom: 1rem;
    }

    @mixin below $medium {
      flex-direction: column;

      .Button:first-child {
        margin-right: 0;
        margin-bottom: 1rem;
      }

      .RadioGroup {
        flex-direction: column;

        & > div:first-child {
          margin-right: 0;
          margin-bottom: 1rem;
        }
      }
    }
  }
}

.CateringDashboard-splash {
  text-align: center;

  img {
    height: 250px;
  }
}

.CateringDashboard-wrapper {
  width: 100%;
  max-width: 70rem !important;
}

.CateringDashboard-grid {
  align-items: stretch !important;

  .u-grid-col {
    margin-bottom: 1rem;
  }

  .Box {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
}

.CateringDashboard-newRequest {

  .Box {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .Button {
    border: 1px solid $primary;
    padding: 1.5rem 4rem;

    &:hover {
      border: 1px solid $primary-dark;
    }
  }
}

.CateringRequest-noBids {
  img {
    width: 150px;
  }
}

}