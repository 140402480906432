.admin-layout {

.ProgressCounter {
  text-align: center;
  cursor: default;
  padding-top: 3rem;
  margin-top: auto;
}

.ProgressCounter-step {
  font-weight: 600;
  font-size: 1rem;
  background: $mediumGrey;
  display: inline-block;
  width: 1rem;
  height: 1rem;
  text-align: center;
  border-radius: 50%;
  line-height: 1;
  margin: 0 0.2rem;
  position: relative;
}

.ProgressCounter-step--current {
  background: $primary;
  color: white;
}

.ProgressCounter-stepNumber {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

}