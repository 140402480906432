.admin-layout {

.Events-toolbar {
  display: flex;
  align-items: center;

  .Events-toolbarLeft {
    display: flex;
    align-items: center;

    h3 {
      margin-bottom: 0;
      margin-top: 1px;
      padding-right: 6px;
    }

    .Button {
      padding: 6px;
      padding-bottom: 4px;
      line-height: 1;
    }
  }

  .Events-toolbarPushLeft {
    margin-left: auto;
    margin-right: 280px;
  }

  .Events-toolbarPushFarLeft {
    margin-left: auto;
    margin-right: 125px;
  }
}

.Events-toolbarRight {
  display: flex;
  align-items: center;
  margin-left: auto;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  z-index: 2;

  &:not(&--list--admin) {
    @mixin below {
      position: fixed !important;
      right: 1rem;
      bottom: 1rem;
    }
  }

  &--list--admin {
    @mixin below {
      width: 100%;
      margin-left: unset;
      justify-content: space-between;
      align-items: start;

      .u-flex {
        flex-direction: column;
        align-items: start !important;

        .Button {
          margin-bottom: .5rem !important;
        }

        .Events-SelectPending {
          margin-left: .5rem;
        }

        .Select {
          margin-top: .5rem;
        }
      }
    }
  }

  &--list {
    position: static;

    .Events-SelectPending {
      padding-top: 3px;

      input {
        padding-top: 1px;
      }
    }
  }
}

.Event.Box {
  padding: 0;
}

.Event-viewEvent {
  margin-left: auto;
  display: flex;
  align-items: center;
}

.Event-header {
  border-bottom: $border;

  & > div {
    padding: 1rem;
    padding-bottom: .75rem;

    &:first-child {
      flex: 2;
    }
  }

  h4 {
    line-height: 1.4rem;
  }

  &-type {
    margin-right: 5px;
    margin-top: 2px;
    width: 17px;
    height: 17px;
    background-color: $secondary;
    border-radius: 50%;

    &--pod {
      background-color: $primary;
    }

    &--denied {
      background-color: $admin-red;
    }
  }

  img {
    vertical-align: bottom;
  }

  .u-mobileOnly {
    padding-top: 0;
    padding-bottom: 0;
  }

  @mixin above $medium {
    display: flex;

    & > div {
      & + div {
        border-left: $border;
      }
    }
  }

  @mixin below $medium {

    &:not(&--admin) {
      padding-bottom: .5rem;
      border-bottom: none;
    }

    .Event-slotsContainer {
      position: relative;
    }

    .Event-slots {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
    }
  }
}

.Event-trucks {
  padding: 1rem;
  margin: -.5rem -.25rem 0 -.25rem;

  @mixin above $medium {
    display: flex;
    flex-wrap: wrap;

    .Event-truck {
      display: inline-block;
      width: calc(33.33% - 0.5rem);
    }
  }

  @media screen and (min-width: $small) and (max-width: $medium) {
    display: flex;
    flex-wrap: wrap;

    .Event-truck {
      width: calc(50% - 0.5rem);
    }
  }

  .Event-truck {
    height: 100%;
    white-space: normal;
    border: $border;
    border-radius: 6px;
    margin: .5rem .25rem 0 .25rem;

    &--checked {
      border-color: $primary !important;
      background-color: #dff2ff !important;
    }

    &.Booking--approved {
      border-color: $admin-green;
      background-color: #ddf8e0;
    }

    &.Booking--pending {
      border-color: #917ffb;
      background-color: #ede9ff;
    }

    &.Booking--denied {
      background-color: #f3f4f6;
      border-color: #b8bac0;
      color: #78838d !important;

      .Event-truckLink {
        color: #78838d !important;
      }
    }

    label {
      padding: .5rem 1rem;
      min-height: 67px;
    }

    input[type='checkbox'] {
      opacity: 0.0;
      position: absolute;
      left: -999px;
    }

    .Event-truckTop {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .Event-truckLink {
      font-weight: 500;
      text-decoration: underline;
      max-width: calc(100% - 110px);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      color: black;

      &:hover {
        color: $primary-dark;
      }
    }

    .Events-selectStatus {
      position: relative;

      .Select {
        width: 100px;
        position: absolute;
        top: 7.5px;
        right: 15px;
      }

      .Select-control {
        border: none;
        box-shadow: none !important;
        background: none;
      }

      .Select-placeholder {
        line-height: unset;
        text-align: right;
        padding-right: 17px;
        padding-left: 3px;
      }

      .Select-arrow-zone {
        padding: 0;
        vertical-align: top;
        width: 14px;
      }

      .Select-menu-outer {
        border-radius: 6px;
      }

      .Select-option:last-child {
        border-bottom-right-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      .Select-option:first-child {
        border-top-right-radius: 6px;
        border-top-left-radius: 6px;
      }
    }
  }

  .Event-emptySlot,
  .Event-cancelledSlot {
    height: 67.5px;
  }

  .Event-emptySlot {
    border-color: $admin-red;
    background-color: #fff4f4;
  }

  .Event-cancelledSlot {
    border-color: #ecd700;
    background-color: #fffcdc;
  }
}

.EventDetails {

  &-dateTime {
    display: flex;
    border: $border;
    text-align: center;

    & > div {
      flex: 1;
      padding: .5rem;

      &:last-child {
        border-left: $border;
      }

      .FormLabel {
        width: 100%;
      }
    }
  }

  &-repeats {
    margin-top: 1.5rem;
  }

  &-trucks {
    .truck {
      display: flex;
      justify-content: space-between;
      border-bottom: $border;
      padding: .25rem 0;
    }

    .Select {
      &-control {
        border: none;
        width: 110px;
      }

      .Select-placeholder {
        text-align: end;
        padding-right: 25px;
        padding-top: 1px;
      }

      .Select-arrow-zone {
        padding-top: 5px;
      }
    }
  }

  &-truckLink {
    margin-top: 6px;
    color: black;

    &:hover {
      color: $primary-dark;
    }
  }

  &-addTruck {
    margin-top: 1rem;

    .Select-placeholder {
      padding-top: 4px;
    }

    .Select-arrow-zone {
      padding-top: 5px;
    }

    .Select-input > input {
      padding-top: 12px;
      padding-bottom: 8px;
    }
  }

  .loadInSheet-button {
    width: 100%;
  }

  .FormLabel.FormLabel--secondary {
    font-size: 1rem;
    margin-top: 2rem;
  }

  .RecurringDisplay {
    font-weight: 500;
    color: $text-light;

    &-weekly {

      .u-flex {
        align-items: center;
      }

      .DayOfWeekPicker {
        flex: 2;
        padding-left: 1rem;
        margin: 0;
      }

      .Button {
        cursor: pointer;
      }
    }

  }

}

}