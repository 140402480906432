.admin-layout {

.BookPod {
  &-filters {
    margin-left: -0.375rem;
    margin-right: -0.375rem;

    .Button.SecondaryOutlineButton {
      border: 1px solid white;
      box-shadow: $box-shadow;
      margin-left: 0.375rem;
      margin-right: 0.375rem;
    }

    .HelpCenterButton img {
      margin-right: 0 !important;
      margin-left: 0.5rem;
      margin-bottom: -0.45rem;
      vertical-align: sub;
    }

    .ShowAvailableButton {
      display: inline-block;
      padding-top: 12px !important;
      padding-bottom: 0 !important;

      .react-toggle-track-x {
        margin-bottom: auto !important;
      }

      label {
        cursor: pointer;
      }

      &:hover svg path {
        fill: white;
      }
    }
  }

  .FilterPaneParent {
    display: inline-block;
  }
}

.FilterPane {
  .slide-pane__content {
    padding-left: 0;
    padding-right: 0;
  }

  h2 {
    padding: 0 32px;
  }

  .Select-placeholder {
    line-height: 51px;
  }

  .DateFilter-label {
    padding-top: 4px;
    padding-right: 5px;
  }

  h3 {
    font-weight: 350;
    font-size: 16px;
  }

  h3 img {
    vertical-align: text-bottom;
    margin-right: 0.5rem;
  }

  .Select .Select-input {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 4px;
  }

  .Select--multi .Select-value {
    margin-left: 6px;
    margin-top: 6px;
  }

  .DateFilter {
    border-radius: 4px;

    .Filter-arrow:before {
      content: '';
      border-color: #999 transparent transparent;
      border-style: solid;
      border-width: 5px 5px 2.5px;
      display: inline-block;
      height: 0;
      width: 0;
      position: relative;
    }
  }

  .DateFilter-closeIcon {
    cursor: pointer;

    &:hover {
      &:before {
        color: #D0021B;
      }
    }
  }

  .DateFilter-closeIcon:before {
    padding-top: 0;
    color: #999;
  }
}

.FilterGroup {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: .25rem;

  & > div {
    vertical-align: middle;
    margin-right: 0.5rem;
  }

  .Select {
    display: inline-block;
    width: 230px;
  }

  & > div {
    margin-bottom: .75rem;
  }

  .ToggleWrapper {
    margin-left: .75rem;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value,
  .DateFilter-label {
    padding-top: 4px;
  }

  .DateFilter .Filter-arrow-zone {
    padding-top: 9px;
  }
}

.PodEvent {
  margin-bottom: .75rem;
  padding: 0;
  cursor: pointer;

  &:hover, &--open {
    box-shadow: 2px 2px 16px #99aab8 !important;
  }

  &-header {
    display: flex;
    border-bottom: $border;

    & > div {
      padding: 1rem;
      padding-bottom: .75rem;

      &:first-child {
        flex: 2;
      }

      & + div {
        border-left: $border;
      }
    }

    img {
      vertical-align: bottom;
    }
  }

  &-bookings {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: .5rem;

    .PodEvent-booking {
      font-weight: 500;
      display: inline-block;
      width: calc(33.33% - 0.5rem);
      margin: 0 .25rem .5rem .25rem;
      border: $border;
      padding: .5rem 1rem;
      border-radius: 6px;
      text-align: center;
      min-height: 67px;

      .badges {
        margin-bottom: -2px;
      }

      .badge {
        line-height: 1.5;
        margin-bottom: .25rem;
      }

      .badge + .badge {
        margin-left: .25rem;
      }

      &--claim {
        border-style: dashed;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          display: inline-block;
          padding-top: .25rem;
        }
      }
    }

  }

  &-footer {
    font-size: $text-sm;
    display: flex;
    align-items: center;

    .Button {
      font-weight: 500;
      margin-left: .5rem;
    }

    & > div:not(.PodEvent-showOnHover) {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1;
      padding: 1rem;

      &:first-child {
        flex: 2;
      }
    }

    .PodEvent-showOnHover {
      padding: 0 1rem 1rem 0 !important;
    }
  }

  .PodEvent-showOnHover {
    display: none;
    padding: 1rem;
    padding-bottom: 0;
  }

  @mixin below $medium {

    &-header {
      flex-direction: column;
      border-bottom: none;

      & > div + div {
        border: none;
      }

      .u-mobileOnly {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

    &-body .PodEvent-showOnHover {
      padding-top: 0;
    }

    &-bookings {
      flex-direction: column;
      margin-bottom: 1rem !important;

      .PodEvent-booking {
        width: 100%;
        margin: 0;
        margin-bottom: .5rem;

        &:first-child {
          margin-top: 1rem;
        }
      }
    }

    &-footer {
      font-size: 1rem;

      & > .PodEvent-showOnHover {
        width: 100%;
        padding: 0 !important;

        & > div {
          width: 100%;
        }
      }

      .BookPodEventPane {
        width: 100%;
        padding: 1rem;
        padding-top: 0;

        .Button {
          width: 100%;
          margin-left: 0;
          padding: 1rem;
          padding-bottom: .75rem;
        }
      }

    }

    .PodEvent-showOnHover {
      display: block;
    }

    .PodEvent-footer {
      & > div:not(.PodEvent-showOnHover) {
        padding-top: 5px;
      }
    }
  }
}

.PodEvent:hover, .PodEvent--open {
  .PodEvent-showOnHover {
    display: block;
  }

  .PodEvent-footer {
    & > div:not(.PodEvent-showOnHover) {
      padding-top: 5px;
    }
  }
}

.ToggleWrapper-repeatBooking {
  font-weight: 500;
  font-size: 14px;
  margin-left: 0;
  margin-right: .5rem;
}

.BookPodPane-document {
  display: flex;
  justify-content: space-between;
  border-bottom: $border;
  padding-top: .5rem;
  padding-bottom: .25rem;

  &-tooltip {
    display: flex !important;
    align-items: center;
    margin-top: -3px;
  }
}

.BookPodPane-address {
  img {
    vertical-align: bottom;
  }
}

.BookPodInfo {
  padding-top: 5rem;
}

.BookPodInfo + .FormSubmitContainer {
  padding-top: 1rem;
}

.BookPod-bookingStatus {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: $border;
  border-radius: $radius;
  padding: 1rem;

  &--first {
    padding-top: 5px;
  }
}

.BookPod-photo {
  height: 200px;
  overflow: hidden;
  margin-bottom: 1rem;
  background-size: cover;
  background-position: center;
  width: 100%;
}

.BookPod-date {
  line-height: 1.5;
  display: flex;
  border-bottom: $border;
  padding: .25rem;
  padding-top: .45rem;

  &--unavailable {
    color: $admin-red;
    text-decoration: line-through;
  }

  button {
    margin-left: auto;
    padding-left: 0.5rem;
    border: none;
    cursor: pointer;
    background: none;

    &:hover {
      color: $admin-red;
    }
  }
}

}