.admin-layout {

.Switch {

}

.Switch.FormRow {
  & + & {
    margin-top: -1rem;
  }
}

.Switch--spaced {

  .ToggleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .ToggleWrapper-labelLeft {
    font-weight: 500;
    font-size: 14px;
  }
}

.Switch--horizontal {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;

  label {
    text-wrap: nowrap;
    margin-bottom: 0;
    line-height: 1;
    padding-top: 7px;
  }

  .ToggleWrapper {
    margin-right: 2rem;
  }

  @mixin below {
    flex-direction: column;
    margin-bottom: 2rem !important;

    .ToggleWrapper {
      margin-top: .7rem;
    }
  }
}

.PaneForm .Switch--horizontal {
  .ToggleWrapper {
    margin-right: 0;
  }
}

}