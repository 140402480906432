.admin-layout {

.CardForm {
  color: white;
  background: $primary;
  border-radius: 6px;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  &:before {
    content: '';
    padding-top: 54%;
  }

  label {
    margin-bottom: 1rem;
  }

  @mixin below {
    max-width: 500px;
    position: relative;

    .CardCurrent {
      width: auto;
      height: auto;
      position: absolute;
      top: 1rem;
      bottom: 1rem;
      left: 1rem;
      right: 1rem;
    }
  }
}

.CardForm-form {
  width: 100%;

  .Button--save,
  .Button--cancel {
    @mixin secondary-text;
    color: white;
    background: none;

    &:hover {
      color: black;
      background: none;
    }
  }

  .Button--cancel {

  }
}

.CardElement {
  background: white;
  margin-bottom: 1rem;
  padding: 0.5rem;
  border-radius: 3px;
}

.CardCurrent {
  margin-bottom: 1rem;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  &-change {
    position: absolute;
    left: 0;
    bottom: 0;
    line-height: 1;
  }

  &-logo {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 60px;
  }

  .Button {
    font-weight: 500;
    font-size: 11px;
    text-transform: uppercase;
    color: white;
    padding: 0;

    &:hover {
      color: black;
    }
  }
}

.CardCurrent-numbers {
  font-weight: 500;
  font-size: 1.25rem;
  letter-spacing: 2px;

  span + span {
    margin-left: 1.5rem;
  }
}

.AccountBalance {
  border: $border;
  margin-right: 2rem;
  display: flex;
  flex-direction: column;

  &-title {
    font-weight: 500;
    border-bottom: $border;
    padding: .5rem;
  }

  &-body {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    width: 100%;
    height: 100%;
  }

  &-balance {
    font-weight: 700;
    font-size: 2rem;
    line-height: 2rem;
    padding-top: .5rem;
    text-align: center;
  }

  .u-text-secondary {
    line-height: 1;
    margin-top: .5rem;
  }
}

.AccountBalance-wrapper {
  display: flex;

  & > div {
    flex: 1 1 50%;
  }

  @mixin below {
    flex-direction: column;

    & > div {
      flex: 1 1 100%;
    }

    .AccountBalance {
      margin-right: 0;
      margin-bottom: 1rem;
      max-width: 500px;
    }
  }
}

}