.admin-layout {

.VendorHeader {
  margin-bottom: 1.5rem;
  display: flex;

  h2 {
    margin-bottom: 0;
  }

  &-right {
    display: flex;
    align-items: center;
    margin-left: auto;
  }

  &-truck {
    font-weight: 500;
    font-size: 13px;
    color: $text-light;
  }

  &-badge .badge {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5;
    padding-right: 1rem;
    padding-left: 1rem;
  }

  .AccountIcon {
    cursor: pointer;
    display: flex;
    height: 35px;
    width: 30px;

    svg {
      width: 100%;
    }

    &:hover svg {
      fill: $text-light;
    }

    &-img {
      border-radius: 50%;
      width: 32px;
      height: 32px;
      background-size: cover;
      background-position: center center;
    }
  }
}

.TruckSwitcher {
  cursor: pointer;
  display: flex;

  @mixin below $medium {
    margin-left: 40px;
    margin-top: .25rem;

    h2 {
      margin-left: 0;
      margin-top: 0;
    }
  }
}

.AdminLayout--sidebarOpen .TruckSwitcher {
  margin-left: 0;
  margin-top: 0;

  h2 {
    margin-left: 0;
    margin-top: 0;
  }
}

.TruckSwitcher-tooltip {
  padding: .25rem;
  padding-bottom: 0;
  font-size: 13px;

  a {
    font-weight: 500;
    display: block;
    color: $text-light;

    & + & {
      margin-top: .45rem;
    }

    &:hover {
      color: black;
    }
  }
}

.TruckSwitcher-arrowContainer {
  margin-left: 5px;
}

.TruckSwitcher-arrow {
  border-color: $text-light transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 0;
  width: 0;
  position: relative;

  &--up {
    top: -2px;
    border-color: transparent transparent $text-light;
    border-width: 0 5px 5px;
  }
}

}