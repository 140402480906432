.admin-layout {

.BudgetSlider {
  position: relative;
  overflow: hidden;
  padding-top: 0.5rem;
  padding: 1rem;
  padding-bottom: 3rem;
}

.BudgetSlider-low, .BudgetSlider-high, .BudgetSlider-value {
  font-weight: 500;
  position: absolute;
  bottom: 1rem;
  line-height: 1;
}

.BudgetSlider-low {
  left: 1rem;
  bottom: 2rem;
}

.BudgetSlider-high {
  right: 1rem;
  bottom: 2rem;
}

.BudgetSlider-value {
  font-weight: 500;
  left: 50%;
  text-align: center;
  transform: translateX(-50%);
  font-size: 2rem;
  color: $primary;
  line-height: 1.3;
}

.BudgetSlider-message {
  color: $grey;
  text-align: center;
  line-height: 1.2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.rheostat {
  overflow: visible;
  position: relative;
  margin-top: 43px;
  margin-bottom: 1rem;
  height: 16px;
}

.rheostat-background {
  background-color: $primary;
  border: 1px solid $primary;
  position: relative;
}

.rheostat-progress {
  background-color: #abc4e8;
  position: absolute;
}

.rheostat-handle {
  background-color: $primary;
  border: 4px solid $primary;
  border-radius: 50%;
  height: 32px;
  outline: none;
  z-index: 2;
  width: 32px;
  top: -14px;
  margin-left: -15px;
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.rheostat-handle:before,
.rheostat-handle:after {
  content: '';
  display: block;
  position: absolute;
  background-color: #dadfe8;
}

}