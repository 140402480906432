.admin-layout {

@define-mixin rounded-input-admin {
  background: white;
  border-radius: $radius;
  display: block;
  padding: 0.5em 0.75em .25rem;
  line-height: 1;
  transition: border 200ms;
  margin-bottom: 0;
  height: 3rem;
  width: 100%;
  border: $border;

  &:focus {
    border: $border;
  }

  &::placeholder {
    color: $otherGrey;
  }
}

@define-mixin secondary-text {
  font-weight: 500;
  font-size: 11px;
  color: $text-light;
  text-transform: uppercase;
}

}