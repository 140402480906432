.admin-layout {

.DashboardWidget-container {

}

.DashboardWidget {
  padding-right: 0;
  padding-left: 0;

  .WidgetLabel {
    width: 100%;
    text-align: center;

    &--large {
      font-size: 48px;
    }

    &--small {
      @mixin secondary-text;
    }
  }

  .WidgetImg {
    width: 200px;
  }
}

.DashboardWidget-header {
  display: flex;
  justify-content: space-between;
  border-bottom: $border;
  padding-right: 1rem;
  padding-left: 1rem;
  padding-bottom: .75rem;

  h3 {
    font-size: 1rem;
    margin: 0;
  }

  a {
    @mixin secondary-text;

    &:hover {
      color: black;
    }
  }
}

.DashboardWidget-content {
  padding-right: 1rem;
  padding-left: 1rem;
  padding-top: 1rem;
}

.Widget-Revenue {

  &-container {
    width: 100%;
    height: 300px;
  }

  .recharts-cartesian-grid line {
    stroke: $border-color;
  }
}

.Widget-Subscribers,
.Widget-Publications {

  .WidgetLabel {
    margin-left: -1rem;
    position: absolute;
    bottom: 85px;
  }

  .PieChart-container {
    width: 100%;
    height: 250px;
  }
}

.Widget-Trucks {
  padding-bottom: 0;

  &-container {
    @mixin secondary-text;
    width: 100%;
    height: 350px;
  }
}

.Widget-PendingBookings {
  padding-bottom: 0;

  .DashboardWidget-content {
    padding: 0;
  }

  &-event {
    border-bottom: $border;
    padding: 1rem;

    &:last-child {
      border: none;
    }

    h4 {
      margin-bottom: .5rem;
      line-height: 1;
    }

    &-date {
      display: flex;
      justify-content: space-between;
      align-items: center;
      line-height: 1;
      margin-top: 1rem;

      & > div {
        display: flex;
        align-items: center;
      }

      .icon-cal {
        margin-bottom: 4px;
      }

      .icon-time {
        margin-bottom: 4px;
      }

      .icon-arrow {
        margin-bottom: 2px;
      }
    }
  }

  &-booking {
    margin-bottom: .5rem;
    justify-content: space-between;

    .Select {
      min-width: 200px;
    }
  }

  &-truckLink {
    margin-top: 3px;
    color: black;

    &:hover {
      color: $primary-dark;
    }
  }

}

.Widget-BookPod {
  background: $primary !important;
  color: white;
  display: block;

  @mixin above {
    .WidgetImg {
      width: 250px;
    }
  }

  @mixin below {
    .DashboardWidget-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }

  &:hover {
    background: $primary-dark;
    color: white;
  }

  &-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 250px;
  }

  .DashboardWidget-content {
    padding: 0 15px;
    display: flex;
  }

}

.Widget-Upgrade {

  .Button {
    @mixin secondary-text;
    color: white;
    font-size: 13px;
    padding: 15px 30px;
    padding-bottom: 10px;
  }

  .WidgetImg {
    width: 250px;
  }
}

.Widget-News {
  padding-bottom: 0;

  .DashboardWidget-content {
    padding: 0;
  }

  h4, .u-text-light {
    margin-bottom: 2px;
  }

  &-sentTo {
    display: flex;
    align-items: center;
    margin-top: .5rem;

    svg {
      height: 15px;
      width: 15px;
      fill: $primary;
      margin-top: -2px;
    }

    span {
      display: block;
      line-height: 1;
    }
  }

  &-message {
    border-bottom: $border;
    padding: 1rem;

    &:last-child {
      border: none;
    }

    a {
      text-decoration: underline;
      color: black;

      &:hover {
        color: $primary-dark;
      }
    }
  }

  &-messageText {
    display: inline-block;
    word-break: break-word;
  }

  &-list {
    max-height: 500px;
    overflow-y: scroll;
    border-bottom: $border;
    box-shadow: inset 0 -10px 10px -10px $box-shadow-color;
  }

  &-form {
    padding: 1rem;
  }

  .MessageForm {
    .FormRow {
      margin-bottom: 1rem;
      padding-left: 0;
      padding-right: 0;
    }

    .Button {
      padding-right: .75rem;

      svg {
        height: 20px;
        width: 20px;
        margin-bottom: 3px;

        path {
          fill: white;
        }
      }
    }

    .Button:hover svg path {
      fill: white;
    }
  }
}

.Widget-Account {
  .Account-lineItems {
    text-align: left;
    border-collapse: collapse;

    td:first-child {
      width: 1px;
      white-space: nowrap;
    }

    td {
      padding: 0 5px;
      padding-top: 3px;
    }

    tr:nth-child(odd) {
      background: $bg;
    }
  }
}

.Widget-Documents {

  &-document {
    display: flex;
    justify-content: space-between;
    padding: 1.5px 0 6.5px;

    & + & {
      border-top: $border;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
    }

    .document-status {
      font-size: 13px;
      color: $text-light;
    }

    img {
      padding-left: .5rem;
      margin-bottom: -3px;
    }
  }

}

.Widget-VendorNews {
  padding-bottom: 0;

  .DashboardWidget-content {
    padding: 0;
  }

  h4, .u-text-light {
    margin-bottom: 2px;
  }

  &-list {
    max-height: 300px;
    overflow-y: scroll;
  }

  &-sentTo {
    display: flex;
    align-items: center;
    margin-top: .5rem;

    span {
      display: block;
      line-height: 1;
    }
  }

  &-message {
    border-bottom: $border;
    padding: 1rem;

    &:last-child {
      border: none;
    }

    a {
      text-decoration: underline;
    }
  }
}

.Widget-Events {
  padding-bottom: 0;

  .DashboardWidget-content {
    padding: 0;
  }

  &-event {
    position: relative;
    border-bottom: $border;
    padding: 1rem;
    cursor: pointer;

    &:last-child {
      border: none;
    }

    h4, .u-text-light {
      margin-bottom: 2px;
    }

    &-podBadge {
      font-weight: 500;
      position: absolute;
      top: 10px;
      right: 1rem;
      background-color: #FDEEC2;
      border-radius: 22px;
      padding: 5px .75rem 0;
    }

    &-date {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & > div {
        display: flex;
        align-items: center;
      }
    }
  }
}

.Widget-Messages {

  &-list {
    margin: -1rem !important;
  }

  &-message {
    border-bottom: $border;
    padding: 1rem;
    display: flex;
    color: black;

    &:hover {
      background: $bg;
      color: black;
    }

    &-top {
      font-weight: 500;
      display: flex;
      justify-content: space-between;
    }

    &:last-child {
      border: none;
    }

    img {
      height: 75px;
      margin-top: -.25rem;
      margin-left: -.25rem;
      margin-right: 1rem;
    }
  }

}

}