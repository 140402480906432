.admin-layout {

h1, h2, h3, h4, h5, h6 {
  font-weight: 600;
  margin-top: 0;

  small {
    font-size: 60%;
  }
}

h2 {
  font-size: 1.6rem;
}

strong, b, .u-strong {
  font-weight: 600;
}

.badge {
  font-weight: 500;
  display: inline-block;
  padding: .25em .75em;
  border-radius: 24px;
  text-align: center;

  &.badge-gold {
    background: #FCD561;
  }

  &.badge-silver {
    background: #D3E4F0;
  }

  &.badge-gray {
    background: $bg;
  }
}

.u-text-light {
  color: $text-light;
}

.u-text-border-color {
  color: $border-color;
}

.u-text-sm {
  font-size: $text-sm;
}

.u-text-lg {
  font-weight: 500;
  font-size: 18px;
}

.u-text-right {
  text-align: right;
}

.u-text-red {
  color: $admin-red;
}

.u-text-green {
  color: $admin-green;
}

.u-text-secondary {
  @mixin secondary-text;
}

.u-lh1 {
  line-height: 1;
}

}