.admin-layout {

.RulePicker-options {
  display: flex;

  label {
    line-height: 1;
    text-align: center;
    padding: 8px 16px;
    margin-right: -1px;
    height: 100%;
    border: $border;
    display: flex;
    align-items: center;

    small {
      padding-top: .25rem;
      display: inline-block;
      font-size: 75%;
    }

    &:hover {
      cursor: pointer;
    }
  }

  input:checked + label,
  input.selected + label {
    background: $primary;
    color: white;
  }

  & > div:first-of-type label {
    border-radius: 24px 0 0 24px;
  }

  & > div:last-of-type label {
    border-radius: 0 24px 24px 0;
  }

  input[type=radio] {
    position: absolute !important;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    width: 1px;
    border: 0;
    overflow: hidden;
  }
}

.RulePicker-rule {
  align-self: stretch;
  margin-top: 1rem;
  input[type=text] {
    width: 20px;
    display: inline-block;
  }
}

.summary {
  font-size: 0.875rem;
}

.btn {
  color: #666;
  font-size: 0.75rem;
  cursor: pointer;
  background: #ffffff;
  padding: 5px 8px 5px 8px;
  width: 100%;
  border: solid #dfe0e4 1px;
  border-right: none;
  text-decoration: none;
  text-align: center;

  &.save {
    width: 25%;
    display: table;
    margin: 0px auto;
    display: none;
  }
}

.btn:hover, .btn.active {
  background: $blue;
  text-decoration: none;
  color: white;
}

.DayOfWeekPicker {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: no-wrap;
  position: relative;
  max-width: 375px;
}

.RulePicker-weekly .DayOfWeekPicker {
  max-width: 325px;
}

.DayOfWeekPicker-label {
  margin-top: 2px;
  font-size: 0.75rem;
  white-space: nowrap;
  margin-right: 1rem;
}

.DayOfWeekPicker-day {
  flex: 1 1 14%;

  .Button {
    font-weight: 500;
    font-weight: bold;
    color: $text-light;
    background: $border-color;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    text-align: center;
    padding: 0;
    padding-top: calc((36px - 1rem) / 2);

    &.active {
      color: white;
      background: $primary;
    }
  }
}

.DayOfMonthPicker {
  margin-top: 1rem;
}

.DayOfMonthPicker-row {
  display: flex;
  flex-direction: row;
  border: $border;
  border-bottom: none;

  &:last-child {
    border-bottom: $border;
  }
}

.DayOfMonthPicker-day {
  flex: 1 1 14%;
  border-right: $border;
  cursor: pointer;

  &:last-child {
    border-right: none;
  }

  > div {
    height: 100%;
    width: 100%;
    padding-top: 3px;
    text-align: center;

    &.active {
      background: $primary;
      color: white;
    }
  }
}

.DayOfMonthPicker-lastDay {
  flex: 1 1 57%;
}

}